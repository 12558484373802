<template>
  <div class="MilvaMarketForm">
    <!-- Approve to go to market -->
    <div class="Row CheckBoxRow">
      <Checkbox
        name="isApproved"
        :text="mixWB('SHOW_ON_MILVA_MARKET')"
        :extraText="mixWB('ACCEPTANCE_HAS_BEEN_RECEIVED')"
        :isSelected="marketData.isApproved"
        @checkbox-click="onIsApprovedClick" />
      <ToolTip :texts="isApprovedTexts" />
    </div>

    <div class="Row">
      <InputField
        name="contactEmail"
        type="email"
        :isRequired="marketData.isApproved"
        :value="marketData.contactEmail"
        :label="`${mixWB('EMAIL_FOR_INQUIRIES_ON_RESALE_ITEMS')}${requiredText}`"
        @on-update="onEmailUpdate" />
    </div>

    <div class="Row">
      <InputField
        name="contactEmail"
        type="date"
        :isRequired="marketData.isApproved"
        :value="marketData.expiryDate"
        :label="`${mixWB('EXPIRATION_DATE')}${requiredText}`"
        @on-update="onDateUpdate" />
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/FormElements/Checkbox.vue'
import ToolTip from '@/components/ToolTip.vue'
import InputField from '@/components/FormElements/InputField.vue'

export default {
  name: 'MilvaMarketForm',
  props: {
    marketData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isApprovedTexts: [
        this.mixWB('PRO_MARKET_INFO_TEXT_1'),
        this.mixWB('PRO_MARKET_INFO_TEXT_2'),
        this.mixWB('PRO_MARKET_INFO_TEXT_3'),
        this.mixWB('PRO_MARKET_INFO_TEXT_4'),
      ],
    }
  },
  computed: {
    requiredText() {
      return this.marketData.isApproved ? ` (${ this.mixWB('REQUIRED') })` : ''
    },

  },
  methods: {
    onIsApprovedClick() {
      this.$emit('market-form-update', {
        ...this.marketData,
        isApproved: !this.marketData.isApproved,
      })
    },
    onEmailUpdate({ value }) {
      this.$emit('market-form-update', {
        ...this.marketData,
        contactEmail: value,
      })
    },
    onDateUpdate({ value }) {
      this.$emit('market-form-update', {
        ...this.marketData,
        expiryDate: value,
      })
    },
  },
  components: {
    Checkbox,
    ToolTip,
    InputField,
  },
}
</script>

<style lang="stylus" scoped>
  .MilvaMarketForm
    display block

  .Row
    display flex
    width 100%
    >>> .InputField,
    >>> .Dropdown
      flex-grow 2
    >>> .ToolTip
      margin-left 5px
    &.CheckBoxRow
      >>> .ToolTip
        margin-top -5px
</style>
