<template>
  <div
    class="Checkbox"
    :class="rootClasses">
    <div
      class="Inner"
      @click="onClick">
      <div class="CheckWrap">
        <CheckIcon v-if="isSelected" />
      </div>
      <div class="TextWrap">
        <span
          v-if="text"
          class="Text"
          v-html="text"/>
        <span
          v-if="extraText"
          class="ExtraText">{{ extraText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'

export default {
  name: 'Checkbox',
  props: {
    name: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    extraText: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    rootClasses() {
      return {
        HasExtraText: !!this.extraText,
        IsDisabled: this.isDisabled,
      }
    },
  },
  methods: {
    onClick() {
      if (this.isDisabled) {
        return
      }

      this.$emit('checkbox-click', this.name)
    },
  },
  components: {
    CheckIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .Checkbox
    display flex
    margin-bottom 10px

    .Inner
      display flex
      align-items center
      cursor pointer

    .CheckWrap
      box(30px)
      margin-right 10px
      flex-shrink 0
      background-color #fff
      border 1px solid $color_grey_dark
      padding 4px
      svg
        fill $color_primary

    .ExtraText
      display block
      font-size 0.875rem
      color $color_grey

    &.HasExtraText
      .Inner
        align-items flex-start
      .Text
        margin-top -2px

    &.IsDisabled
      .Inner
        cursor default
      .CheckWrap
        border 1px solid $color_grey_lighter
        svg
          fill $color_grey_light
</style>
