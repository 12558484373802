<template>
  <div
    class="ToolTip"
    :class="rootClasses">
    <!-- Icon -->
    <div
      class="Icon"
      @click="onToolTipClick">
      <div class="InnerIconWrap">
        <QuestionIcon />
      </div>
    </div>

    <!-- Backdrop -->
    <div
      v-if="showToolTip"
      class="BackDrop"
      @click="onBackDropClick" />

    <!-- Overlay -->
    <div
      class="Overlay"
      v-if="showToolTip">
      <p
        v-for="text in texts"
        :key="text"
        v-html="text"/>
    </div>
  </div>
</template>

<script>
import QuestionIcon from '@/assets/svg/question.svg?inline'

export default {
  name: 'ToolTip',
  props: {
    texts: {
      type: Array,
      required: true,
    },
    iconSize: {
      type: String,
      required: false,
      default: 'medium', // 'small' | 'medium'
    },
    overlaySize: {
      type: String,
      required: false,
      default: 'medium', // 'small' | 'medium'
    },
  },
  data() {
    return {
      showToolTip: false,
    }
  },
  computed: {
    rootClasses() {
      return {
        IconSizeSmall: this.iconSize === 'small',
        IconSizeMedium: this.iconSize === 'medium',
        OverlaySizeSmall: this.overlaySize === 'small',
        OverlaySizeMedium: this.overlaySize === 'medium',
      }
    },
  },
  methods: {
    onToolTipClick() {
      this.showToolTip = true
    },
    onBackDropClick() {
      this.showToolTip = false
    },
  },
  components: {
    QuestionIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .ToolTip
    position relative
    display block

    .Icon
      flex-center-children()
      cursor pointer
      .InnerIconWrap
        flex-center-children()
        border 1px solid $color_grey_light
        svg
          fill $color_grey

    &.IconSizeSmall
      .Icon
        box(30px)
        .InnerIconWrap
          circle(20px)
          padding 4px
    &.IconSizeMedium
      margin-right -8px
      .Icon
        box(40px)
        .InnerIconWrap
          circle(26px)
          padding 5px

    .BackDrop
      position fixed
      top 0
      left 0
      z-index 1
      width 100vw
      height 100vh
      background-color rgba(255, 255, 255, 0.1)

    .Overlay
      position absolute
      top 50%
      right 50%
      min-height 25px
      padding 10px
      background-color #fff
      border 1px solid $color_grey_lighter
      z-index 2
      box-shadow $box_shadow_1
      p
        color $color_grey
        font-size 0.85rem
        text-align left
        &:last-child
          padding-bottom 0

    &.OverlaySizeSmall
      .Overlay
        width 200px
    &.OverlaySizeMedium
      .Overlay
        width 260px
</style>
